const dev = {
  api: `https://api.thrivein.app/`,
  socket: `ws://api.thrivein.app:5002/`,
}

const prod = {
  api: `https://api.thrivein.app/`,
  socket: `ws://api.thrivein.app:5002/`,
}

const configs = { prod, dev }

const config = configs[process.env.REACT_APP_API_ENV || 'dev']


export default config
