import { useCallback, useEffect, useMemo } from 'react'
import useReducerState from './useReducerState'
import request from '../helpers/request'


/*
 хук имеет смысл использовать когда на кнопку нужно повесить метод с запросом на сервер
 и индикацией загрузки
 */


const initialState = {
  data: null,
  isFetching: false,
  isFetched: false,
}

const useFetch = (method, api, params) => {
  const [ { data, isFetching, isFetched }, setState ] = useReducerState(initialState)

  const fetch = useCallback(({ mock, ..._params } = {}) => {
    _params = {
      ...params,
      ..._params,
      setIsFetching: (isFetching) => setState({ isFetching }),
    }

    if (mock) {
      setTimeout(() => setState({ data: mock, isFetched: true }), 1000)
      return
    }

    return request[method](api, _params)
      .then((data) => {
        setState({ data, isFetched: true })

        return data
      })
      .finally(() => setState({ isFetched: true }))
  }, [ method, api ])

  const results = useMemo(
    () => data && data.results ? [ ...data.results ] : data || [],
    [ data ],
  )

  const  { prev, next } = data || {}

  const fetchNext = useCallback(() => fetch(next), [ fetch, next ])
  const fetchPrev = useCallback(() => fetch(prev), [ fetch, prev ])

  useEffect(() => {

    return () => {
      setState(initialState)
    }
  }, [ fetch ])

  return {
    data, results,
    isFetching, isFetched,
    isNextAvailable: Boolean(next),
    isPrevAvailable: Boolean(prev),
    fetch, fetchNext, fetchPrev,
    setState,
  }
}


export default useFetch
