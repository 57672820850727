// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3jYJNk545rU5BIZkOZJJJC {\n  width: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 20px;\n  position: relative;\n}\n._1f9um_u1WSLKDOGmAdUfdD {\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_3jYJNk545rU5BIZkOZJJJC",
	"isStretch": "_1f9um_u1WSLKDOGmAdUfdD"
};
module.exports = exports;
