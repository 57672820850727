import React from 'react'
import { Form, Input, Button } from 'antd'


const layout = {
  labelCol: { md: { span: 8 } },
  wrapperCol: { md: { span: 16 } },
}

const tailLayout = {
  wrapperCol: { md: { offset: 8, span: 16 } },
}

const SetPasswordForm = (props) => {
  const { onSubmit, isFetching } = props

  return (
    <Form
      {...layout}
      name="setPassword"
      onFinish={onSubmit}
    >
      <Form.Item
        label="New password"
        name="password"
        rules={[{ required: true, message: 'Required' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button
          block
          type="primary"
          htmlType="submit"
          disabled={isFetching}
          loading={isFetching}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SetPasswordForm
