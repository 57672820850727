// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TcfS4R7tBrWLp-4S7lJ7P {\n  width: 90px;\n  height: 90px;\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate3d(-50%, -50%, 0);\n  background: rgba(0, 0, 0, 0.1);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 9px;\n}\n", ""]);
// Exports
exports.locals = {
	"overlay": "TcfS4R7tBrWLp-4S7lJ7P"
};
module.exports = exports;
