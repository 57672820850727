import React from 'react'
import { Spin } from 'antd'

import s from './Loader.module.less'


const Loader = (props) => {
  const { className, ...rest } = props

  return (
    <Spin
      className={className}
      {...rest}
    />
  )
}


export default Loader
