import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import links from 'helpers/links'
import usePageViews from 'hooks/usePageViews'

import './App.less'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import BasicAppLayout, { withBasicAppLayout } from 'layouts/BasicAppLayout/BasicAppLayout'

import Home from 'pages/Home/Home'
import UserAgreement from 'pages/userAgreement'
import Help from 'pages/help'
import Download from 'pages/download'
import Privacy from 'pages/privacy'
import ConfirmEmail from 'pages/confirmEmail'
import SetPassword from 'pages/setPassword'
import Gdpr from 'pages/gdpr'
import SignUp from './pages/SignUp/SignUp'
import CreateProfile from './pages/CreateProfile/CreateProfile'
import ThankYou from './pages/ThankYou/ThankYou'


const App = () => {
  usePageViews()

  return (
    <>
      <Switch>
        <Route path={links.signUp} component={SignUp} />
        <Route path={links.welcome} component={CreateProfile} />
        <Route path={links.thankYou} component={ThankYou} />

        <Route path={links.help} component={withBasicAppLayout(Help)} />
        <Route path={links.gdpr} component={withBasicAppLayout(Gdpr)} />
        <Route path={links.download} component={withBasicAppLayout(Download)} />
        <Route path={links.privacy} component={withBasicAppLayout(Privacy)} />
        <Route path={links.userAgreement} component={withBasicAppLayout(UserAgreement)} />
        <Route path={links.confirmEmail2} component={withBasicAppLayout(ConfirmEmail)} />
        <Route path={links.confirmEmail} component={withBasicAppLayout(ConfirmEmail)} />
        <Route path={links.setPassword2} component={withBasicAppLayout(SetPassword)} />
        <Route path={links.setPassword} component={withBasicAppLayout(SetPassword)} />

        <Route path={links.home} component={Home} />
      </Switch>
    </>
  )
}


export default App
