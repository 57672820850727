import getAlert from './getAlert'


const getOKAlert = (alertProps = {}) => {
  const { title = 'Done', message = 'Your request has been submitted', actionTitle = 'OK' } = alertProps

  return getAlert({
    title,
    message,
    buttons: [
      {
        text: actionTitle,
        style: 'cancel',
      },
    ],
  })
}


export default getOKAlert
