const deepLinkBase = 'thrivein://'

const createLinks = (links) => Object.keys(links).reduce((result, current) => {
  const link = links[current]

  if (typeof link === 'function') {
    return {
      ...result,
      [current]: (...data) => `${deepLinkBase}${link(...data)}`,
    }
  }

  return {
    ...result,
    [current]: `${deepLinkBase}${link}`,
  }
})

const deeplinks = {
  setPassword: (query) => `set-password/:${query}`,
  login: 'login',
}


export default createLinks(deeplinks)
