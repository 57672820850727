import React from 'react'
import { Row, Spin, Typography, Result, Button } from 'antd'

import useConfirm from './util/useConfirm'
import deeplinks from '../../helpers/deeplinks'


const { Text } = Typography

const ConfirmEmail = (props) => {
  const { isFetched, isError } = useConfirm()

  return (
    <Row justify="center" align="center">
      {
        !isFetched && (
          <Spin size="large" />
        )
      }
      {
        isFetched && (
          <>
            {
              isError ? (
                <Result
                  status="error"
                  title="Error occurred. Please, try again."
                />
              ) : (
                <Result
                  status="success"
                  title="Your e-mail is confirmed"
                  extra={[
                    <Button type="link" href={deeplinks.login}>To the App</Button>,
                  ]}
                />
              )
            }
          </>
        )
      }
    </Row>
  )
}


export default ConfirmEmail
