import React from 'react'

import s from './WhiteSpace.module.less'


const WhiteSpace = (props) => {
  const { className, gutter, ...rest } = props

  return (
    <div
      className={className}
      style={{ paddingTop: `${gutter * 2}px` }}
      {...rest}
    />
  )
}

WhiteSpace.defaultProps = {
  gutter: 3,
}


export default WhiteSpace
