import { useLocation, useParams } from 'react-router-dom'
import qs from 'query-string'
import { useCallback, useFetch, useMemo } from 'hooks'
import api from 'helpers/api'


const getValues = (values, isNew) => {
  if (isNew) {
    return {
      new_password2: values.password,
      new_password1: values.password,
    }
  }

  return values
}

const useSetPassword = () => {
  const { uid, token } = useParams()
  const { search } = useLocation()

  const params = useMemo(() => qs.parse(search), [ search ])

  const { isFetching, isError, fetch, data } = useFetch(
    'post',
    uid ? api.resetPassword2 : api.resetPassword,
    { withToken: false, withAutoSnakeCase: false },
  )

  const reset = useCallback(
    (values) => fetch({
      body: { ...getValues(values, Boolean(uid)), ...(uid ? { uid, token } : params) },
    }),
    [ uid, token, fetch, params ],
  )

  return {
    isFetching, isError,
    reset,
    data,
  }
}


export default useSetPassword
