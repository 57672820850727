import { useLocation, useParams } from 'react-router-dom'
import qs from 'query-string'
import { useFetchEffect } from 'hooks'
import api from 'helpers/api'


const useConfirm = () => {
  const { code } = useParams()
  const { search } = useLocation()

  const { isFetched, isError } = useFetchEffect(
    'post',
    code ? api.confirmEmail2 : api.confirmEmail,
    { body: code ? { key: code } : qs.parse(search), withToken: false },
  )

  return {
    isFetched,
    isError,
  }
}


export default useConfirm
