// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2GKDOwrY5R_EQMg8keLKRq.ant-btn-link.ant-btn-icon-only {\n  color: rgba(0, 0, 0, 0.7);\n}\n._3Azt-DDKxVla8xo3cWI-CO {\n  background: #f7c94e;\n  border-color: #f7c94e;\n}\n", ""]);
// Exports
exports.locals = {
	"button": "_2GKDOwrY5R_EQMg8keLKRq",
	"secondary": "_3Azt-DDKxVla8xo3cWI-CO"
};
module.exports = exports;
