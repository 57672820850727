import React from 'react'
import { Row, Result, Typography, Button } from 'antd'
import { useEffect } from 'hooks'
import device from 'helpers/device'
import currentStoreLink from 'helpers/currentStoreLink'

import WidthContainer from 'components/layout/WidthContainer/WidthContainer'
import gtag from '../../helpers/analytics/gtag'


const { Text } = Typography

const Download = (props) => {
  useEffect(() => {
    if (device.isMobile) {
      gtag('event', 'download_page_device', {
        value:device.os,
      })
      window.location = currentStoreLink
    }
  }, [])

  return (
    <WidthContainer>
      <Text>
        If you haven't been redirected, please click a link below to download the app.
      </Text>
    </WidthContainer>
  )
}


export default Download
