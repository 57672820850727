import reducers from '../reducers'
import request from '../../helpers/request'
import api from '../../helpers/api'


const setUserFields = (value) => reducers.app.setUserFields(value)
const setTalents = (value) => reducers.app.setTalents(value)
const setIsFetching = (value) => reducers.app.setIsFetching(value)
const setEventsFilters = (value) => reducers.app.setEventsFilters(value)
const resetEventsFilters = (value) => reducers.app.resetEventsFilters(value)
const setPeopleFilters = (value) => reducers.app.setPeopleFilters(value)
const resetPeopleFilters = (value) => reducers.app.resetPeopleFilters(value)


const fetchUserFields = () => {
  return request.get(api.userFields)
    .then(({ results }) => setUserFields(results))
}

const fetchTalents = () => {
  return request.get(api.talents)
    .then(({ results }) => setTalents(results))
}

export {
  setUserFields,
  fetchUserFields,

  setTalents,
  fetchTalents,

  setIsFetching,
  resetPeopleFilters,
  setPeopleFilters,
  resetEventsFilters,
  setEventsFilters,
}
