// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2DpBTIS5g4EF9yH3Dnwian {\n  height: 600px;\n}\n._3Y1WEUuDXXWyj-PGtrFrbm {\n  padding-top: 80px;\n  height: calc(100vh - 40px);\n}\n", ""]);
// Exports
exports.locals = {
	"map": "_2DpBTIS5g4EF9yH3Dnwian",
	"slide": "_3Y1WEUuDXXWyj-PGtrFrbm"
};
module.exports = exports;
