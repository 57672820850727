import createApiLinks from './createApiLinks'


const apiV1 = createApiLinks({
  confirmEmail: 'accounts/verify-registration',
  resetPassword: 'accounts/reset-password',
}, 'api/v1/')

const apiV2 = createApiLinks({
  confirmEmail2: 'accounts/confirm',
  resetPassword2: 'accounts/password/reset/confirm',
  confirmEmailChange: 'accounts/email/confirm',
  talents: 'talents',
  myProfile: 'users/current',
  oauth: (provider) => `accounts/login/${provider}`,
}, 'api/v2/')

const api = {
  ...apiV1,
  ...apiV2,
}


export default api
