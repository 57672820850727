import React from 'react'
import { Button, DatePicker, Form, Input, InputNumber, Select, Space, Tag, Typography, Upload } from 'antd'
import cx from 'classnames'

import s from './CreateProfileForm.module.less'
import WhiteSpace from '../../../components/ui/WhiteSpace/WhiteSpace'
import Carousel from '../../../components/ui/Carousel/Carousel'
import { useCallback, useFetch, useRef, useState } from '../../../hooks'
import WidthContainer from '../../../components/layout/WidthContainer/WidthContainer'
import { mapFormValues, mapInitialFormValues } from '../../../helpers/formValues'
import api from '../../../helpers/api'
import actions from '../../../redux/actions'
import { connect } from 'redaction'
import TalentsSelect from '../../../components/form/TalentsSelect/TalentsSelect'
import { email } from '../../../helpers/validation'


const CreateProfileForm = (props) => {
  const {
    className,
    myData, data,
    onComplete,
    ...rest
  } = props

  const initialValues = mapInitialFormValues(myData)
  const rootClassName = cx(s.cardsContainer, className)

  const [ form ] = Form.useForm()
  const carousel = useRef()
  const [ talentsFormValue, setTalentsFormValue ] = useState(initialValues.talents)
  const [ emailFormValue, setEmailFormValue ] = useState(initialValues.email)

  const withTalentsFormValue = Boolean(talentsFormValue && talentsFormValue.length)

  const { fetch: putProfile, isFetching } = useFetch(
    'put',
    api.myProfile,
    { withFlattenValues: false },
  )

  const handleNext = useCallback((value) => {
    if (!value) {
      return
    }

    carousel.current.slickNext()
  }, [])

  const handleSubmit = useCallback((values) => {
    console.log({ values })
    const parsedValues = mapFormValues(values)

    // TODO сделать на сервере
    parsedValues.avatar = parsedValues.photos && parsedValues.photos[0]
    //parsedValues.photos = undefined
    //parsedValues.avatar = undefined

    putProfile({ body: { ...myData, ...parsedValues } })
      .then(async (data) => {
        await actions.me.setData(data)

        if (typeof onComplete === 'function') {
          onComplete(data)
        }
      }, (errors) => {
        if (errors) {
          //form.setErrors(errors)
        }
      })

  }, [ putProfile, myData, onComplete ])

  const welcomeSlideContent = (
    <WidthContainer>
      <Typography.Title level={3}>Hi, welcome to ThriveIN</Typography.Title>
      <Typography.Paragraph>
        ThriveIN is a platform that allows talents such as signers, producers, musicans, models and others, to find each other, apply for jobs and other collaborative opportunities.
      </Typography.Paragraph>
      <Typography.Paragraph>
        We are making a closed launch. Follow the next steps to complete your profile and get your invitation.
      </Typography.Paragraph>
      <WhiteSpace gutter={9} />
      <Button
        type="primary"
        block
        onClick={handleNext}
      >
        Let's go
      </Button>
    </WidthContainer>
  )

  const emailSlideContent = (
    <WidthContainer>
      <Form.Item
        label="Your email"
        name="email"
      >
        <Input
          placeholder="example@gmail.com"
          onChange={(e) => setEmailFormValue(e.target.value)}
        />
      </Form.Item>
      <Form.Item>
        <Button
          block
          onClick={handleNext}
          type="primary"
          disabled={email(emailFormValue) || !emailFormValue}
        >
          Next
        </Button>
      </Form.Item>
    </WidthContainer>
  )

  const talentsSlideContent = (
    <WidthContainer>
      <Form.Item
        label="Pick your talents"
        name="talents"
      >
        <TalentsSelect multiple onChange={setTalentsFormValue} />
      </Form.Item>
      <Form.Item>
        <Button
          block
          onClick={handleNext}
          type="primary"
          disabled={!withTalentsFormValue}
        >
          Next
        </Button>
      </Form.Item>
    </WidthContainer>
  )

  const descriptionSlide = (
    <WidthContainer>
      <Form.Item
        label="Tell us a bit about yourself"
        name="userDescription"
      >
        <Input.TextArea
          placeholder="A grammy-winner thriver..."
        />
      </Form.Item>
      <WhiteSpace gutter={5} />
      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          onClick={handleNext}
        >
          Send
        </Button>
      </Form.Item>
    </WidthContainer>
  )

  const slides = [
    welcomeSlideContent,
    emailSlideContent,
    talentsSlideContent,
    descriptionSlide,
  ]

  return (
    <Form
      form={form}
      name="createEventForm"
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Carousel
        ref={carousel}
        className={rootClassName}
        options={{
          arrows: false,
          infinite: false,
          swipe: false,
        }}
      >
        {
          slides.map((slide, index) => (
            <div key={index} className={s.slide}>
              {slide}
            </div>
          ))
        }
      </Carousel>
    </Form>

  )
}

CreateProfileForm.defaultProps = {

}


export default connect({
  userFields: 'app.userFields',
  talents: 'app.talents',
  myData: 'me.data',
})(CreateProfileForm)
