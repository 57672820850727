import { mapValues } from 'lodash'
import moment from 'moment'
import { serverBirthdayFormat } from './time'


const getLocationFormValue = (value) => {
  if (!value || !value.geoData) {
    return value
  }

  const { geoData: { coords: { latitude: lat, longitude: lng } }, label } = value

  return {
    label,
    coords: { lat, lng },
  }
}

const getDateTimeValue = (value) => value ? moment(value).toDate() : undefined

const getValueId = (value) => value && value.id ? value.id : null

export const formValuesMap = {
  //avatar: getValueId,
  photos: (value) => value && value.map(({ response, uid }) => response && response.id || uid),
  currentLocation: getLocationFormValue,
  location: getLocationFormValue,
  birthday: (value) => value ? moment(value).format(serverBirthdayFormat) : undefined,
  datetime: getDateTimeValue,
}

const initialFormValuesMap = {
  ...formValuesMap,
  birthday: (value) => value ? moment(value, serverBirthdayFormat) : undefined,
  datetime: (value) => value ? moment(value) : undefined,
  sport: getValueId,
  talents: (value) => value && value.map((_value) => _value && _value.id),
  photos: (value) => (value && value.map(({ id, file }) => ({
    uid: id,
    name: id,
    url: file,
    status: 'done',
  }))),
  avatar: getValueId,
}

export const mapFormValues = (values) => mapValues(
  values,
  (value, key) => formValuesMap[key] ? formValuesMap[key](value) : value,
)

export const mapInitialFormValues = (values) => mapValues(
  values,
  (value, key) => initialFormValuesMap[key] ? initialFormValuesMap[key](value) : value,
)

export const getGender = (value) => ({
  'M': 'Мужчина',
  'F': 'Женщина',
})[value] || null
