// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2eKBOqKVPCMATVUgOqBwR_ {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  padding-top: 99px;\n  background: #F8F8F8;\n  text-align: center;\n}\n._1Yionw5QX7f0kHqOQ8YwJS {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 16px;\n}\n._1Yionw5QX7f0kHqOQ8YwJS svg {\n  width: 99px;\n  height: 99px;\n}\n._3LHEInQ97d2_6wtiz4FNet {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 40px;\n}\n._3LHEInQ97d2_6wtiz4FNet > *:not(:last-child) {\n  margin-bottom: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"pageContainer": "_2eKBOqKVPCMATVUgOqBwR_",
	"header": "_1Yionw5QX7f0kHqOQ8YwJS",
	"buttons": "_3LHEInQ97d2_6wtiz4FNet"
};
module.exports = exports;
