import React from 'react'
import { Row, Result, Typography, Button, Layout } from 'antd'

import WidthContainer from 'components/layout/WidthContainer/WidthContainer'

import s from './SignUp.module.less'

import Logo from '../../components/ui/Logo/Logo'
import GoogleButton from '../../components/auth/GoogleButton/GoogleButton'
import FacebookButton from '../../components/auth/FacebookButton/FacebookButton'


const SignUp = (props) => {

  return (
    <div className={s.pageContainer}>
      <div className={s.header}>
        <Logo />
      </div>
      <WidthContainer>
        <Typography.Title level={3}>Hello, please sign in</Typography.Title>
        <Typography.Paragraph>
          To access your ThriveIn profile choose one of the following authentication methods below to prove your identity and continue.
        </Typography.Paragraph>
        <div className={s.buttons}>
          <GoogleButton />
          <FacebookButton />
        </div>

      </WidthContainer>
    </div>
  )
}


export default SignUp
