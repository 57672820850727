let isStorageAvailable

try {
  window.localStorage.setItem('test', 'test')

  isStorageAvailable = true

  window.localStorage.removeItem('test')
}
catch (e) {
  console.log('local storage is not available')

  isStorageAvailable = false
}

const fireLocalStorageMethod = (method) => (...params) => {
  if (isStorageAvailable) {
    return window.localStorage[method](...params)
  }
  else {
    console.log(`can't fire local storage method ${method}`)

    return null
  }
}

const setItem = (key, data) => {
  fireLocalStorageMethod('setItem')(key, typeof data === 'object' ? JSON.stringify(data) : data)
}

const getItem = (key) => {
  const data = fireLocalStorageMethod('getItem')(key)

  try {
    return JSON.parse(data)
  }
  catch (e) {
    return data
  }
}

export const removeItem = fireLocalStorageMethod('removeItem')

const localStorage = {
  setItem,
  getItem,
  removeItem,
}

export default localStorage
