import reducers from '../reducers'
import request from '../../helpers/request'
import api from '../../helpers/api'
import localStorage from '../../helpers/localStorage'
import getOKAlert from '../../helpers/getOkAlert'
import device from '../../helpers/device'
import actions from '../actions'


const setData = async (data) => {
  localStorage.setItem('userData', data)

  reducers.me.setData(data)
}

const fetchData = () =>
  request.get(api.myProfile)
    .then(async (data) => {
      await setData(data)

      return data
    }, Promise.reject)
    .catch(Promise.reject)

const removeData = async () => {
  localStorage.removeItem('userData')
  localStorage.removeItem('token')
  reducers.me.setData({})
  reducers.me.resetNotificationsData()

  //if (!device.isAndroid) {
  //  PushNotificationIOS.setApplicationIconBadgeNumber(0)
  //}
}

const setIsLoggedIn = (isLoggedIn) => {
  reducers.me.setIsLoggedIn(isLoggedIn)
}

const registerInvite = async () => {
  const inviteCode = localStorage.getItem('inviteCode')

  if (inviteCode) {
    localStorage.removeItem('inviteCode')

    await request.post(api.inviteRef, { body: { invite_code: inviteCode } })
  }
}

const setUpUser = async ({ loginMethod } = {}) => {
  reducers.me.setLoginMethod(loginMethod)

  const userData = await fetchData()
  //await fetchNotificationsCount()
  //await actions.app.fetchUserFields()
  await actions.app.fetchTalents()
  //await registerInvite()

  return userData
}

const setFlag = async (flagName, value, data = {}) => {
  const flagData = {
    [flagName]: {
      value,
      data: {
        ...data,
        timestamp: Date.now(),
      },
    },
  }

  await request.patch(api.myFlags, {
    withAutoSnakeCase: false,
    body: {
      ...flagData,
    },
  })
  reducers.me.updateFlags(flagData)
}

const setUsername = async (username) => {
  localStorage.setItem('username', username)

  reducers.me.setUsername(username)
}

const tryGetUsername = async () => {
  const username = localStorage.getItem('username')

  reducers.me.setUsername(username)

  return username
}

const report = async (type, { id, ...data } = {}) => {
  await request.post(api.report, { body: { model_type: type, model_id: id, data } })
  getOKAlert()()
}

const block = async (id) => {
  await request.post(api.block, { body: { user_id: id } })
  getOKAlert()()
}

const fetchNotificationsCount = () => {
  return request.get(api.notificationsCount)
    .then((data) => {
      const { total } = data

      if ('setAppBadge' in navigator && 'clearAppBadge' in navigator) {
        if (total) {
          navigator.setAppBadge(total)
        }
        else {
          navigator.clearAppBadge()
        }
      }

      reducers.me.setNotificationsData(data)
    })
}

const readNotifications = (ids) => {
  return request.post(api.notificationsRead, {
    body: { notification_ids: ids },
  })
    .then(() => Promise.all([
      fetchNotificationsCount(),
      actions.notifications.fetch(),
    ]))
}

export {
  setData,
  fetchData,
  removeData,

  fetchNotificationsCount,
  readNotifications,

  setUpUser,
  setFlag,

  setUsername,
  tryGetUsername,

  setIsLoggedIn,

  report,
  block,
}
