import React from 'react'
import { Layout, Row, Col, Typography } from 'antd'

import LogoLink from 'components/ui/LogoLink/LogoLink'
import Box from 'components/ui/Box/Box'
import WidthContainer from 'components/layout/WidthContainer/WidthContainer'
import AppStoreButton from '../../components/ui/AppStoreButton/AppStoreButton'
import PlayStoreButton from '../../components/ui/PlayStoreButton/PlayStoreButton'


const { Footer, Header, Content } = Layout
const { Text } = Typography

const BasicAppLayout = (props) => {
  const { children } = props

  return (
    <Layout>
      <WidthContainer>
        <Box pt="sm" pb="sm">
          <Row gutter={9} align="middle">
            <Col>
              <LogoLink />
            </Col>
            <Col>
              <Text strong>ThriveIN</Text>
            </Col>
          </Row>
        </Box>
      </WidthContainer>
      <Content>
        {children}
      </Content>
      <WidthContainer>
        <Box pt="sm" pb="sm">
          <Text>
            ThriveIN © 2021
          </Text>
        </Box>
        <Row>
          <Box pt="sm" pr="sm">
            <AppStoreButton />
          </Box>
          <Box pt="sm">
            <PlayStoreButton />
          </Box>
        </Row>
      </WidthContainer>
    </Layout>
  )
}

export const withBasicAppLayout = (Component) => (props) => {

  return (
    <BasicAppLayout>
      <Component {...props} />
    </BasicAppLayout>
  )
}


export default BasicAppLayout
