import createLinks from './createLinks'


const links = {
  home: '',

  signUp: 'sign-up',

  confirmEmail: 'confirm',
  confirmEmailChange: 'confirm/email/:code',
  confirmEmail2: 'confirm/:code',
  setPassword: 'set-password',
  setPassword2: 'reset/:uid/:token',

  privacy: 'privacy',
  help: 'help',
  gdpr: 'gdpr',
  userAgreement: 'user-agreement',
  download: 'download',
  signUp: 'sign-up',
  welcome: 'welcome',
  thankYou: 'thank-you',
}


export default createLinks(links)

export const generateLink = () => ''
