import React from 'react'
import cx from 'classnames'
import { FacebookFilled } from '@ant-design/icons'

import { useHistory } from 'react-router-dom'
import s from './FacebookButton.module.less'
import Button from '../../ui/Button/Button'
import {
  useCallback,
  useEffect,
  useFetch,
  useRef,
  useScriptLoader,
} from '../../../hooks'
import api from '../../../helpers/api'
import actions from '../../../redux/actions'
import links from '../../../helpers/links'
import googleApiKey from '../../../helpers/config/googleApiKey'



const FacebookButton = (props) => {
  const {
    className,
    ...rest
  } = props

  const { isLoaded, isFetching } = useScriptLoader('https://connect.facebook.net/en_US/sdk.js')
  const isLoadedRef = useRef(false)
  const { fetch: signUpWithFacebook } = useFetch('post', api.oauth('facebook'))

  const history = useHistory()

  const rootClassName = cx(s.facebookButton, className)

  const initFacebook = useCallback(() => {
    window.FB.init({
      appId: '361315185112838',
      cookie: true,
      xfbml: true,
      version: 'v11.0',
    })
  }, [])

  const handleButtonClick = useCallback(() => {
    window.FB.login((response) => {
      const token = response.authResponse.accessToken

      signUpWithFacebook({
        withLoader: true,
        body: {
          access_token: token,
        },
      })
        .then(
          (data = {}) => {
            const { isExists } = data

            // if (isExists) {
            //  logLogin('google')
            // }
            // else {
            //  logSignUp('google')
            // }
            actions.me.setIsLoggedIn(true)
            actions.me.setUpUser({ loginMethod: 'facebook' })
              .then((data) => {
                // const { flags } = data
                //
                // if (flags && flags.isIntroWizardShown) {
                //   history.replace(links.home)
                // }
                // else {
                  history.replace(links.welcome)
                // }
              })
          },
          (errors) => {
          },
        )
    })
  }, [])

  useEffect(() => {
    if (isLoaded && !isLoadedRef.current) {
      initFacebook()

      isLoadedRef.current = true
    }

  }, [ initFacebook, isLoaded ])

  return (
    <Button
      className={rootClassName}
      size="large"
      onClick={handleButtonClick}
      disabled={isFetching}
      {...rest}
    >
      <FacebookFilled />
      Continue with Facebook
    </Button>
  )
}


export default FacebookButton
