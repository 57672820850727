import React from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Result, Typography, Button, Layout } from 'antd'

import WidthContainer from 'components/layout/WidthContainer/WidthContainer'

import s from './CreateProfile.module.less'
import CreateProfileForm from '../../composiitions/profile/CreateProfileForm/CreateProfileForm'
import links from '../../helpers/links'
import Logo from '../../components/ui/Logo/Logo'


const CreateProfile = (props) => {
  const history = useHistory()

  return (
    <div className={s.pageContainer}>
      <div className={s.header}>
        <Logo />
      </div>
      <WidthContainer>
        <Layout.Content>
          <CreateProfileForm
            onComplete={() => history.push(links.thankYou)}
          />
        </Layout.Content>
      </WidthContainer>
    </div>
  )
}


export default CreateProfile
