import React from 'react'
import { Row, Result, Typography, Button, Col } from 'antd'

import WidthContainer from 'components/layout/WidthContainer/WidthContainer'
import SetPasswordForm from './components/SetPasswordForm/SetPasswordForm'

import useSetPassword from './util/useSetPassword'
import deeplinks from '../../helpers/deeplinks'


const { Text } = Typography

const SetPassword = (props) => {
  const { isFetching, isError, reset, data } = useSetPassword()

  const isFormVisible = Boolean(isError || !data)

  return (
    <WidthContainer>
      <Row justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 9 }}>
          {
            isError && (
              <Text type="error">Error occurred. Please, try again.</Text>
            )
          }
          {
            isFormVisible && (
              <SetPasswordForm onSubmit={reset} isFetching={isFetching} />
            )
          }
          {
            Boolean(!isError && data) && (
              <Result
                status="success"
                title="Your password has been changed"
                extra={[
                  <Button type="link" href={deeplinks.login}>To the App</Button>,
                ]}
              />
            )
          }
        </Col>
      </Row>
    </WidthContainer>
  )
}


export default SetPassword
