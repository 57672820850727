import React from 'react'
import { connect } from 'redaction'
import { Button, DatePicker, Form, Input, InputNumber, Select, Space, Tag, Typography, Upload } from 'antd'
import { PushpinOutlined, ClockCircleOutlined, PlusOutlined } from '@ant-design/icons'
import cx from 'classnames'

import s from './TalentsSelect.module.less'


const TalentsSelect = (props) => {
  const {
    className,
    multiple,
    talents,
    ...rest
  } = props

  const rootClassName = cx(s.cardsContainer, className)

  const _props = multiple ? {
    mode: 'multiple',
    allowClear: true,
  } : {}

  return (
    <Select
      className={rootClassName}
      placeholder="Begin to type here"
      {..._props}
      {...rest}
    >
      {
        Boolean(talents) && talents.map((sport, index) => {
          const { title, id } = sport

          return (
            <Select.Option
              key={index}
              value={id}
            >
              {title}
            </Select.Option>
          )
        })
      }
    </Select>
  )
}

TalentsSelect.defaultProps = {

}


export default connect({
  talents: 'app.talents',
})(TalentsSelect)
