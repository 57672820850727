import currentDevice from 'current-device'


const device = {
  isIOS: currentDevice.os === 'ios',
  isAndroid: currentDevice.os === 'android',

  isMobile: currentDevice.type === 'mobile',

  os: currentDevice.os,
}


export default device
