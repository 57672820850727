import { useEffect, useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useLogOut from './useLogOut'
import localStorage from '../helpers/localStorage'
import actions from '../redux/actions'
import links from '../helpers/links'
import { useState } from './index'


const useInitialLoad = (props) => {

  const [ isReady, setIsReady ] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const logOut = useLogOut()

  const { search } = location

  const locationFromRef = useRef(location.pathname)

  const token = localStorage.getItem('token')

  const isRouteUnderAuth = location.pathname === links.welcome || location.pathname === links.thankYou

  const replace = useCallback((screenName, state) => {
    if (isRouteUnderAuth) {
      history.replace(screenName, state)
    }

    setIsReady(true)
  }, [ isRouteUnderAuth, history ])

  const init = useCallback(async () => {
    Promise.all([
        //initGoogleAnalytics(),
        actions.me.tryGetUsername(),
      ])
      .then(() => {
        if (token) {
          try {
            actions.me.setIsLoggedIn(true)
            actions.me.setUpUser()
              .then(
                (data) => {
                  replace(`${links.thankYou}${search}`, { to: locationFromRef.current })
                },
                () => {
                  if (isRouteUnderAuth) {
                    logOut()
                  }
                },
              )
          }
          catch (e) {
            replace(`${links.signUp}${search}`)
          }
        }
        else {
          replace(`${links.signUp}${search}`)
        }
      })
  }, [ isRouteUnderAuth, token, logOut, replace, search ])

  useEffect(() => {
    if (!isReady) {
      init()
    }
  }, [ init, isReady ])

  return isReady
}


export default useInitialLoad
