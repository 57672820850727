import { useCallback } from 'react'
import actions from '../redux/actions'
import { useHistory } from 'react-router-dom'
import links from '../helpers/links'


const useLogOut = () => {
  const history = useHistory()

  return useCallback(async () => {
    await actions.me.removeData()
    actions.me.setIsLoggedIn(false)

    history.push(links.signUp, [])
  }, [ history ])
}


export default useLogOut
