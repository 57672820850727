import React from 'react'

import Logo from 'components/ui/Logo/Logo'
import Link from 'components/ui/Link/Link'
import links from '../../../helpers/links'


const LogoLink = (props) => {
  const { children } = props

  return (
    <Link to={links.home}>
      <Logo />
    </Link>
  )
}


export default LogoLink
