import { mapValues } from 'lodash'


const flattenValue = (value, shouldFlatten) => {
  if (typeof value === 'object' && shouldFlatten) {
    return value.id || value
  }

  return value
}

const flattenValues = (values, params) => mapValues(values, (value, key) => {
  const shouldFlatten = !params || params && params.includes(key)

  if (value instanceof Array) {
    return value.map((value) => {
      if (typeof value === 'object') {
        return flattenValue(value, shouldFlatten)
      }

      return value
    })
  }

  return flattenValue(value, shouldFlatten)
})


export default flattenValues
