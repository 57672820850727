import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import cx from 'classnames'
import s from './GoogleButton.module.less'
import Button from '../../ui/Button/Button'
import { useCallback, useEffect, useFetch, useRef, useScriptLoader } from '../../../hooks'
import api from '../../../helpers/api'
import actions from '../../../redux/actions'
import googleApiKey from '../../../helpers/config/googleApiKey'
import links from '../../../helpers/links'


const GoogleButton = (props) => {
  const {
    className,
    ...rest
  } = props

  const { isLoaded, isFetching } = useScriptLoader('https://apis.google.com/js/api.js')
  const isLoadedRef = useRef(false)
  const { fetch: signUpWithGoogle } = useFetch('post', api.oauth('google'))
  const history = useHistory()

  const rootClassName = cx(s.googleButton, className)


  const updateSigninStatus = useCallback(() => {
    const user = window.GoogleAuth.currentUser.get()

    signUpWithGoogle({
      withLoader: true,
      body: {
        access_token: user.getAuthResponse().access_token,
      },
    })
      .then(
        (data = {}) => {
          const { isExists } = data

          //if (isExists) {
          //  logLogin('google')
          //}
          //else {
          //  logSignUp('google')
          //}
          actions.me.setIsLoggedIn(true)
          actions.me.setUpUser({ loginMethod: 'google' })
            .then((data) => {
              const { flags } = data

              // if (flags && flags.isIntroWizardShown) {
              //   history.replace(links.home)
              // }
              // else {
                history.replace(links.welcome)
              // }
            })
        },
        (errors) => {
        },
      )
  }, [ history, signUpWithGoogle ])

  const initGoogle = useCallback(() => {
    window.gapi.client.init({
      'apiKey': googleApiKey,
      'clientId': '73614346063-6jdgld2a47jjgpcai7oii79sl27jai4l.apps.googleusercontent.com',
      'scope': 'https://www.googleapis.com/auth/userinfo.profile',
    }).then(() => {
      window.GoogleAuth = window.gapi.auth2.getAuthInstance()

      window.GoogleAuth.isSignedIn.listen(updateSigninStatus)
    })
  }, [ updateSigninStatus ])

  const handleButtonClick = useCallback(() => {
    if (window.GoogleAuth.isSignedIn.get()) {
      updateSigninStatus()
    }
    else {
      window.GoogleAuth.signIn()
    }
  }, [ updateSigninStatus ])

  useEffect(() => {
    if (isLoaded && !isLoadedRef.current) {
      window.gapi.load('client', initGoogle)

      isLoadedRef.current = true
    }

  }, [ initGoogle, isLoaded ])

  return (
    <Button
      className={rootClassName}
      size="large"
      onClick={handleButtonClick}
      disabled={isFetching}
      {...rest}
    >
      <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
          <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
          <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
          <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
          <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
        </g>
      </svg>
      Continue with Google
    </Button>
  )
}


export default GoogleButton
