import React from 'react'
import { connect } from 'redaction'
import Loader from '../../../components/ui/Loader/Loader'

import s from './ConnectedLoader.module.less'


const ConnectedLoader = (props) => {
  const { isFetching } = props

  return isFetching && (
    <div className={s.overlay}>
      <Loader
        size="large"
      />
    </div>
  )
}

ConnectedLoader.defaultProps = {
  isFetching: false,
}

export default connect({
  isFetching: 'app.isFetching',
})(ConnectedLoader)
