// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._21e-RG1RiJjJZVKGFNQA1t {\n  height: 500px;\n}\n._1T-hjKkXcV-QvT8m_8GA91 {\n  height: calc(100vh - 50px);\n}\n", ""]);
// Exports
exports.locals = {
	"map": "_21e-RG1RiJjJZVKGFNQA1t",
	"slide": "_1T-hjKkXcV-QvT8m_8GA91"
};
module.exports = exports;
