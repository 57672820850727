import localStorage from '../../helpers/localStorage'


const initialState = {
  data: {},
  loginMethod: null,
  notificationsData: {},
  isLoggedIn: Boolean(localStorage.getItem('token')),
  isInitiallyLoggedIn: Boolean(localStorage.getItem('token')),
}

const setData = (state, payload) => ({
  ...state,
  data: payload,
})

const setNotificationsData = (state, payload) => ({
  ...state,
  notificationsData: payload,
})

const resetNotificationsData = (state) => ({
  ...state,
  notificationsData: {},
})

const setLoginMethod = (state, payload) => ({
  ...state,
  loginMethod: payload,
})

const updateFlags = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    flags: {
      ...state.data.flags,
      ...payload,
    },
  },
})

const setUsername = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    username: payload,
  },
})

const setIsLoggedIn = (state, payload) => ({
  ...state,
  isLoggedIn: payload,
})


const reset = () => ({
  ...initialState,
})


export default {
  initialState,

  setData,
  setNotificationsData,
  resetNotificationsData,
  setUsername,
  setLoginMethod,
  setIsLoggedIn,
  updateFlags,
  reset,
}
