import React from 'react'
import qs from 'query-string'
import LiveChat from 'react-livechat'

import WidthContainer from 'components/layout/WidthContainer/WidthContainer'
import { useLocation } from 'react-router-dom'


const Gdpr = (props) => {
  const { search } = useLocation()
  const { name, email } = qs.parse(search)

  return (
    <WidthContainer>
      <p>
        If you want us to delete something feel free to contact us via email&nbsp;
        <a href="mailto:hi@thrivein.app">hi@thrivein.app</a>
      </p>
      <p>
        You can also chat with us. Check the chat button at the bottom right corner.
      </p>
      <LiveChat
        license="12244206"
        visitor={{ name, email }}
      />
    </WidthContainer>
  )
}


export default Gdpr
