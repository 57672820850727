// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".fZYd7iZNZ_X8NFAUHYJO3 {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  padding-top: 99px;\n  background: #F8F8F8;\n  text-align: center;\n}\n._2iinpm-HkDWBqNuXCRCLVn {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 16px;\n}\n._2iinpm-HkDWBqNuXCRCLVn svg {\n  width: 99px;\n  height: 99px;\n}\n", ""]);
// Exports
exports.locals = {
	"pageContainer": "fZYd7iZNZ_X8NFAUHYJO3",
	"header": "_2iinpm-HkDWBqNuXCRCLVn"
};
module.exports = exports;
