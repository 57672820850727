import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import gtag from 'helpers/analytics/gtag'


const usePageViews = () => {
  const location = useLocation()

  useEffect(() => {
    gtag('event', 'page_view', {
      page_path: location.pathname,
    })
  }, [ location ])
}


export default usePageViews
