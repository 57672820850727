import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'

import App from './App'
import store from './redux/store'
import Preloader from './containers/Preloader/Preloader'
import ConnectedLoader
  from './composiitions/misc/ConnectedLoader/ConnectedLoader'


const Root = () => {

  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <Preloader>
          <App />
          <ConnectedLoader />
        </Preloader>
      </BrowserRouter>
    </ReduxProvider>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))
