import axios from 'axios'
import { setIsFetching as setIsAppFetching } from '../redux/app/actions'
import flattenValues from './flattenValues'
import { getCamelCasedData, getSnakeCaseData } from './dataCaseHelpers'
import config from './config'


const defaultOptions = {
  withToken: true,
  setIsFetching: () => {},
  withFlattenValues: true,
  withAutoSnakeCase: true,
  setDebugError: () => {},
}

const baseURL = config.api
//const baseURL = 'http://10.0.2.2:8000/api/v1/'


// TODO дописать это гавно
const convertDataDeeply = (method) => (data) => {
  if (data instanceof Array) {
    return data.map(convertDataDeeply(method))
  }

  if (typeof data === 'object') {
    return null
  }
}

const request = (method) => async (url, options = {}) => {
  options = {
    ...defaultOptions,
    ...options,
  }

  const {
    withLoader,
    setIsFetching, auth,
    withToken, withFlattenValues, withAutoSnakeCase,
    setDebugError,
    headers = {},
    query, body,
    ...restOptions
  } = options

  let axiosOptions = {
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    ...restOptions,
  }

  if (withToken) {
    const token = localStorage.getItem('token')

    if (token) {
      axiosOptions.headers['Authorization'] = `Token ${token}`
    }
  }

  if (auth) {
    axiosOptions.auth(...options.auth)
  }

  const params = query && withAutoSnakeCase ? getSnakeCaseData(query) : query || undefined
  const flattenBody = withFlattenValues ? flattenValues(body) : body

  let data = (body && withAutoSnakeCase) ? getSnakeCaseData(flattenBody) : flattenBody || undefined

  if (data && typeof data === 'object' && !Object.keys(data).length) {
    data = undefined
  }


  const axiosPromise = axios({
    method,
    url: `${baseURL}${url}/`,
    params,
    data,
    ...axiosOptions,
  })

  return new Promise((resolve, reject) => {
    setIsFetching(true)

    if (withLoader) {
      setIsAppFetching(true)
    }

    return axiosPromise
      .then(async (response) => {
        const { data: rawData } = response

        console.log(method, url, response)
        console.log('data', rawData)

        if (rawData.token) {
          console.log('new token', rawData.token)

          localStorage.setItem('token', rawData.token)
        }

        const data = getCamelCasedData(rawData)

        if (data.results && data.results instanceof Array) {
          data.results = data.results.map(getCamelCasedData)
        }

        resolve(data)
      })
      .catch((error) => {
        const errorData = error && error.response && error.response.data
        const status = error && error.response && error.response.status

        console.log('error', method, url, error, error.toJSON && error.toJSON())
        console.log('error response', error.response)
        console.log('error response message', errorData)

        setDebugError(errorData ? JSON.stringify({ ...errorData, status }) : status)

        reject(errorData)
      })
      .finally(() => {
        setIsFetching(false)

        if (withLoader) {
          setIsAppFetching(false)
        }
      })
  })
}

request.get = request('get')
request.post = request('post')
request.patch = request('patch')
request.put = request('put')
request.delete = request('delete')


export default request
