import React from 'react'
import { Row, Result, Typography, Button } from 'antd'

import WidthContainer from 'components/layout/WidthContainer/WidthContainer'

import s from './Home.module.less'
import PlayStoreButton from '../../components/ui/PlayStoreButton/PlayStoreButton'
import AppStoreButton from '../../components/ui/AppStoreButton/AppStoreButton'


import img from './images/img.jpg'
import img2x from './images/img@2x.jpg'
import img3x from './images/img@3x.jpg'

import logo from './images/logo.png'
import logo2x from './images/logo@2x.png'
import logo3x from './images/logo@3x.png'


const Home = (props) => {

  return (
    <div className={s.pageContainer}>
      <div className={s.logoContainer}>
        <img
          src={logo3x}
          srcSet={`${logo}, ${logo2x} 2x, ${logo3x} 3x`}
          alt="ThriveIN"
        />
      </div>
      <WidthContainer>
        <div className={s.title}>
          ThriveIN - Connect, Collaborate & Create with Creatives
        </div>
        <div className={s.text}>
          <p>
            ThriveIN is a creative platform that allows talents such as singers, producers, musicians, models and others, to find each other, apply for jobs and other collaborative opportunities.
          </p>
          <p>
            The platform creates an industry-focused network that would help build relationships both locally or internationally. Thrivers would get rated, reviews on their work and be able to showcase their videos, music or image projects.
          </p>
          <p>
            Thrive Collective was launched in Dubai in 2013 and has hosted networking events for the creative industry in Dubai, LA, Geneva, Trinidad and Tobago and Bali. With over 150 talents showcased, 60+ speakers, hosted, and 100+ events, Thrive plans to be the No1 network for creatives with signature events, knowledge resources, community platforms, and physical creative spaces by 2025.
          </p>
          <p>
            <strong>Join the Thriver community and <a>#KeepThrivein</a></strong>
          </p>
        </div>
        <div className={s.buttons}>
          <PlayStoreButton />
          <AppStoreButton />
        </div>
        <div className={s.image}>
          <img
            src={img3x}
            srcSet={`${img}, ${img2x} 2x, ${img3x} 3x`}
            alt="ThriveIN"
          />
        </div>
        <div className={s.footer}>
          ThriveIN © 2021
        </div>
      </WidthContainer>
    </div>
  )
}


export default Home
