// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1UsIMe5VEssbsYUG3AJ6CD {\n  width: 250px;\n  display: flex;\n  align-items: center;\n  padding: 20px;\n  box-shadow: 0 0 5px 0.3px rgba(0, 0, 0, 0.1);\n}\n._1DKTXNedvC4l6H6cuuvrjZ {\n  width: 250px;\n  display: flex;\n  align-items: center;\n  padding: 20px;\n  box-shadow: 0 0 5px 0.3px rgba(0, 0, 0, 0.1);\n  background: #ffffff;\n  border-color: #ffffff;\n  color: #4285F4;\n}\n._1DKTXNedvC4l6H6cuuvrjZ svg {\n  width: 15px;\n  height: 15px;\n  margin-right: 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"socialButton": "_1UsIMe5VEssbsYUG3AJ6CD",
	"googleButton": "_1DKTXNedvC4l6H6cuuvrjZ"
};
module.exports = exports;
