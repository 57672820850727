import { useState, useRef } from 'react'


const loadedScripts = {}

const useScriptLoader = (src) => {
  const isLoaded = Boolean(loadedScripts[src])
  const ref = useRef(false)
  const [ state, setState ] = useState({ isFetching: !isLoaded, isLoaded, error: null })

  // check if we on client side by getElementsByTagName bcs document.head is mocked in site/server
  const headElements = document && document.getElementsByTagName('head')
  const isClientSide = headElements && headElements.length

  if (!isLoaded && !ref.current && isClientSide) {
    ref.current = true

    const script = document.createElement('script')

    script.async = true
    script.type = 'text/javascript'
    script.src = src

    script.onerror = (err) => {
      console.error(`Script "${src}" load error: ${err}`)

      if (script.parentNode) {
        script.parentNode.removeChild(script)
      }

      setState({ isFetching: false, isLoaded: false, error: err })
    }

    script.onload = () => {
      console.log(`Script "${src}" loaded`)

      loadedScripts[src] = true
      setState({ isFetching: false, isLoaded: true, error: null })
    }

    if (headElements && headElements[0]) {
      headElements[0].appendChild(script)
    }
  }

  return state
}


export default useScriptLoader
