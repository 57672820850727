// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1NLF_glp_X4ANikLE2hbmg {\n  width: 60px;\n  height: 60px;\n}\n", ""]);
// Exports
exports.locals = {
	"logo": "_1NLF_glp_X4ANikLE2hbmg"
};
module.exports = exports;
