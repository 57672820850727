import React from 'react'
import { Layout, Divider, Typography } from 'antd'

import WidthContainer from '../../../components/layout/WidthContainer/WidthContainer'

import s from './PreloaderScreen.module.less'
import Loader from '../../../components/ui/Loader/Loader'


const PreloaderScreen = (props) => {

  return (
    <div className={s.layout}>
      <Loader size="large" />
    </div>
  )
}


export default PreloaderScreen
