import React from 'react'
import { Button as AntButton, Typography } from 'antd'
import { NavLink, useHistory } from 'react-router-dom'
import cx from 'classnames'

import s from './Button.module.less'
import { useCallback } from '../../../hooks'


const Button = (props) => {
  const {
    className, secondary, linkTo, linkProps,
    onClick,
    isLink,
    ...rest
  } = props

  const rootClassName = cx(className, s.button, {
    [s.secondary]: secondary,
  })

  const history = useHistory()

  const handleLinkClick = useCallback((e) => {
    e.preventDefault()
    onClick(e)
    history.push(linkTo)
  }, [ onClick, history, linkTo ])

  if (linkTo || isLink) {

    return (
      <NavLink
        className={rootClassName}
        component={isLink ? Typography.Link : AntButton}
        to={linkTo}
        onClick={handleLinkClick}
        {...linkProps}
        {...rest}
      />
    )
  }

  return (
    <AntButton
      className={rootClassName}
      onClick={onClick}
      {...rest}
    />
  )
}

Button.defaultProps = {
  onClick: () => {},
}


export default Button
