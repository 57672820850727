import React from 'react'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'


const Link = (props) => {
  const {
    to, children,
    className: _className, activeClassName,
    ...rest
  } = props

  const isRouterLink = Boolean(to)

  const className = cx(_className)

  const componentProps = {
    ...rest,
    className,
  }

  if (isRouterLink) {
    componentProps.activeClassName = activeClassName
  }

  return React.createElement(isRouterLink ? NavLink : 'a', { ...componentProps, to }, children)
}


export default Link
