// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3paYh4to8Vq9W_pU3-RTh0 {\n  min-height: 100vh;\n  background: #F8F8F8;\n}\n._3S0bQSryKinvs-MEaaJNJl {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 16px;\n}\n._3S0bQSryKinvs-MEaaJNJl svg {\n  width: 32px;\n  height: 32px;\n}\n", ""]);
// Exports
exports.locals = {
	"pageContainer": "_3paYh4to8Vq9W_pU3-RTh0",
	"header": "_3S0bQSryKinvs-MEaaJNJl"
};
module.exports = exports;
