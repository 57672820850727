import React, { useEffect } from 'react'
import useInitialLoad from '../../hooks/useInitialLoad'
import useInviteCheck from '../../hooks/useInviteCheck'
import PreloaderScreen from '../../composiitions/misc/PreloaderScreen/PreloaderScreen'


const Preloader = (props) => {
  const { children } = props

  const isLoaded = useInitialLoad()

  return (
    <>
      {
        isLoaded ? children : (
          <PreloaderScreen />
        )
      }
    </>
  )
}


export default Preloader
