const initialState = {
  userFields: null,
  talents: null,
  isFetching: false,
  eventsFilters: {
    distance: 60,
  },
  peopleFilters: {
    distance: 60,
  },
}

const setUserFields = (state, payload) => ({
  ...state,
  userFields: [ ...payload ],
})

const setTalents = (state, payload) => ({
  ...state,
  talents: [ ...payload ],
})

const setIsFetching = (state, payload) => ({
  ...state,
  isFetching: payload,
})

const resetPeopleFilters = (state) => ({
  ...state,
  peopleFilters: {
    distance: 60,
  },
})

const setPeopleSort = (state, payload) => ({
  ...state,
  peopleSort: payload,
})

const resetEventsFilters = (state) => ({
  ...state,
  eventsFilters: {},
})

const setPeopleFilters = (state, payload) => ({
  ...state,
  peopleFilters: payload,
})

const setEventsFilters = (state, payload) => ({
  ...state,
  eventsFilters: payload,
})

const reset = () => ({
  ...initialState,
})


export default {
  initialState,

  setUserFields,
  setTalents,
  setIsFetching,
  setPeopleFilters,
  setPeopleSort,
  setEventsFilters,
  resetPeopleFilters,
  resetEventsFilters,
  reset,
}
