const gtag = (...params) => {
  if (process.env.REACT_APP_API_ENV === 'prod') {
    window.gtag(...params)
  }
  else {
    console.log('gtag fake', ...params)
  }
}


export default gtag
