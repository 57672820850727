import messages from './messages'


const isEmpty = (value) => value === undefined || value === null || value === ''

/* eslint-disable max-len */
export const email = (value) => {
  const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  if (!isEmpty(value) && !regex.test(value)) {
    return messages.email
  }
}
/* eslint-enable max-len */

export const required = (value) => {
  if (isEmpty(value)) {
    return messages.required
  }
}

export const username = (value) => {
  // TODO check
  const regex = /^[a-zA-Z0-9](_(?!(\.|_))|\.(?!(_|\.))|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$/

  if (!regex.test(value)) {
    return messages.username
  }
}

export function requiredCoordinates(value) {
  if (isEmpty(value) || value.length < 1 || value instanceof Array && value.find((item) => !item || !item.x || !item.y)) {
    return messages.required
  }
}

export const requiredTrue = (value) => {
  if (!isEmpty(value) && value !== true) {
    return messages.required
  }
}

export function streetAddress(value) {
  if (`${value}`.includes('@')) {
    return 'Must be a valid street address'
  }
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`
    }
  }
}

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`
    }
  }
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return 'Must be a number'
  }
}


export function birthday(value) {
  if (!value || !value.replace(/[\/_]/g, '')) {
    return false
  }

  const minDate = new Date('01/01/1930')
  const maxDate = new Date()
  const date = new Date(value)

  if ((date < minDate) ||
    (date > maxDate) ||
    (!Date.parse(value))) {
    return 'Must be a valid date'
  }
}
