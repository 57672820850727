import React, { forwardRef } from 'react'
import Slider from 'react-slick'

import s from './Carousel.module.less'


const defaultOptions = {}

const Carousel = forwardRef((props, ref) => {
  const { className, options, children, ...rest } = props

  return (
    <Slider
      className={className}
      ref={ref}
      {...rest}
      {...defaultOptions}
      {...options}
    >
      {children}
    </Slider>
  )
})


export default Carousel
