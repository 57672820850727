// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3eJ3KWIc-nlp5V1yoo16pL {\n  width: 250px;\n  display: flex;\n  align-items: center;\n  padding: 20px;\n  box-shadow: 0 0 5px 0.3px rgba(0, 0, 0, 0.1);\n}\n.yUQG6v9xAC1hPzNYER5Y5 {\n  width: 250px;\n  display: flex;\n  align-items: center;\n  padding: 20px;\n  box-shadow: 0 0 5px 0.3px rgba(0, 0, 0, 0.1);\n  background: #ffffff;\n  border-color: #ffffff;\n  color: #4267B2;\n}\n", ""]);
// Exports
exports.locals = {
	"socialButton": "_3eJ3KWIc-nlp5V1yoo16pL",
	"facebookButton": "yUQG6v9xAC1hPzNYER5Y5"
};
module.exports = exports;
