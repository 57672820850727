import React from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Result, Typography, Button, Layout } from 'antd'

import WidthContainer from 'components/layout/WidthContainer/WidthContainer'

import s from './ThankYou.module.less'
import CreateProfileForm from '../../composiitions/profile/CreateProfileForm/CreateProfileForm'
import links from '../../helpers/links'
import Logo from '../../components/ui/Logo/Logo'


const ThankYou = (props) => {
  const history = useHistory()

  return (
    <div className={s.pageContainer}>
      <div className={s.header}>
        <Logo />
      </div>
      <WidthContainer>
        <Typography.Title level={3}>Thank You</Typography.Title>
        <Typography.Paragraph>
          We will contact you via email and send you your invitation and profile link.
        </Typography.Paragraph>
      </WidthContainer>
    </div>
  )
}


export default ThankYou
