import moment from 'moment'

// TODO учесть, чтобы выводился прошлый год
export const displayDateFormat = 'D MMMM YYYY'
export const displayTimeFormat = 'H:mm'
export const displayDateTimeFormat = `${displayDateFormat} ${displayTimeFormat}`
export const serverBirthdayFormat = 'YYYY-MM-DD'

export const getTimeFromNow = (time) => {
  if (moment(time).isBefore(moment(moment().format(displayDateFormat)))) {
    return moment(time).format(displayDateTimeFormat)
  }
  else {
    return moment(time).fromNow()
  }
}

export const getAgeFromBirthday = (birthday) => moment().diff(moment(birthday, serverBirthdayFormat), 'years')
