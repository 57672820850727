const createApiLinks = (links, baseUrl) => {
  return Object.keys(links).reduce((result, current) => {
    const oldApiItem = links[current]

    let newApiItem

    if (typeof oldApiItem === 'string') {
      newApiItem = `${baseUrl}${oldApiItem}`
    }
    else if (typeof oldApiItem === 'function') {
      newApiItem = (...params) => `${baseUrl}${oldApiItem(...params)}`
    }

    return {
      ...result,
      [current]: newApiItem,
    }
  }, {})
}


export default createApiLinks
