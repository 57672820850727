import React from 'react'

import s from './Logo.module.less'
import logoImage from './images/logo.png'


const Logo = (props) => {

  return (
    <svg className={s.logo} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30Z" fill="url(#paint0_radial)" />
      <path d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30Z" fill="url(#paint1_radial)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.4999 21.4872C33.1201 21.4872 35.2441 19.3634 35.2441 16.7436C35.2441 14.1238 33.1201 12 30.4999 12C27.8798 12 25.7558 14.1238 25.7558 16.7436C25.7558 19.3634 27.8798 21.4872 30.4999 21.4872ZM30.4999 49C28.7913 49 28.2227 48.6205 27.6408 44.4675C27.232 41.5497 26.8884 39.1907 26.5745 37.0357L26.5744 37.0348C26.3884 35.7583 26.2129 34.5533 26.0404 33.3461C25.566 30.0256 25.1903 29.3926 21.5809 27.559C17.9716 25.7254 13.0415 22.0564 11.99 20.3065C11.3336 18.8308 12.3773 18.1667 15.1224 19.8314C18.9112 22.1603 24.9944 24.3333 30.4999 24.3333C36.0054 24.3333 42.0886 22.1603 45.8774 19.8314C48.6225 18.1667 49.6662 18.8308 49.0099 20.3065C47.9583 22.0564 43.0282 25.7254 39.4189 27.559C35.8095 29.3926 35.4338 30.0256 34.9594 33.3461C34.7869 34.5536 34.6113 35.7588 34.4253 37.0357L34.4253 37.036C34.1114 39.1909 33.7678 41.5498 33.359 44.4675C32.7771 48.6205 32.2085 49 30.4999 49Z" fill="white" />
      <defs>
        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(37.6344 60) rotate(-109.2) scale(38.5373)">
          <stop stopColor="#D700FF" />
          <stop offset="1" stopColor="#FFBE00" />
        </radialGradient>
        <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.24687 55.7016) rotate(-108.561) scale(50.5679)">
          <stop stopColor="#00E4FF" />
          <stop offset="1" stopColor="#FFBE00" stopOpacity="0.01" />
        </radialGradient>
      </defs>
    </svg>
  )
}


export default Logo
