// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".R4JIwmVHOP7on3SYQYCXn {\n  width: 100%;\n  height: 100vh;\n  background: #F8F8F8;\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"layout": "R4JIwmVHOP7on3SYQYCXn"
};
module.exports = exports;
